import React, {Component} from "react";
import {Header} from "semantic-ui-react";

class AboutExpenses extends Component {
    render() {
        return(
            <>
                <div className="content">
                    <p>Mit diesem Formular kannst du neu die Spesen digital und ganz ohne Papier einreichen. Du brauchst einfach nur ein Foto zu machen und alle Angaben einzugeben.</p>
                    <Header as="h3">Fragen zu den Finanzen</Header>
                    <p>Urs Forrer v/o MIR<br/>
                    finanzen@ceviduernten.ch</p>
                </div>
            </>
        );
    }
}

export default AboutExpenses;